body {
  touch-action: manipulation;
  font-family: 'Roboto';
  font-weight: normal;
}

button:disabled {
  touch-action: manipulation;
}

svg text {
  user-select: none;
}
